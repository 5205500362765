<template>
  <div class="add-product-wrap">
    <div
      class="add-product-btn"
      @click="toggleModels()"
    >
      <span :class="{ reverse: !showModels }" class="arrow"></span>
      Add User Bike
    </div>

    <div v-show="showModels">
      <div class="adding-bike-form">
        <f-input-text-area v-model="serialNumbers" title="A list of serial numbers separated by commas." />

        <div class="main__content-btns" style="display: flex">
          <button
            class="btn btn-fw"
            style="width: 100px !important; background: #C2E900 !important;margin: 0 auto !important;padding: 3px 2px; border-radius: 4px !important; font-style: normal !important;"
            @click="findAndAddBike"
          >
            Add Bikes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FInputTextArea from "@/components/inputs/TextArea.vue";

export default {
  name: "AddBike",
  components: { FInputTextArea },
  computed: {
    ...mapGetters({
      mode_edit: "orderItem/canEdit",
      was_successful_payment: "orderPrice/wasSuccessfulPayment",
      can_product_edit: "orderItem/canProductEdit",
    }),
  },
  data() {
    return {
      showModels: false,
      serialNumbers: "",
    };
  },
  methods: {
    findAndAddBike() {
      if (this.serialNumbers.trim() === "") {
        this.$root.$emit("modalShow", { type: "error", text: "Please fill serial numbers" });
        return;
      }
      this.$store.dispatch("bikes/addToOrder", {
        orderID: this.$route.params.id,
        serials: this.serialNumbers,
      }).then(result => {
        this.$root.$emit("modalShow", { type: "info", text: `${result} bike(s) added to order.` });

        this.$root.$emit("updateOrder");

        this.showModels = false
      }).catch(err => {
        this.$root.$emit("modalShow", { type: "error", text: err });
      });
    },
    toggleModels() {
      this.showModels = !this.showModels;
    },
  },
};
</script>

<style scoped>
.add-product-wrap {
  width: calc(100% - 8px);
  margin-bottom: 10px;
}

.add-product-btn {
  padding: 8px 10px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  color: #454545;
}

.arrow {
  margin-right: 8px;
}
</style>
