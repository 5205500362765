<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>{{ !createMode ? "Updating" : "Creating" }} Product Build</span>
    </template>

    <template v-slot:content>
      <input
        id="blocks-image-uploaded"
        :key="`block-image-${nonce}`"
        :disabled="loading"
        accept="image/*"
        class="hidden"
        maxlength="1"
        type="file"
        @change="handleImage"
      >
      <input
        id="blocks-manuals-uploaded"
        :key="`block-manual-${manualNonce}`"
        :disabled="loading"
        accept="application/pdf"
        class="hidden"
        maxlength="1"
        type="file"
        @change="handleManual"
      >
      <input
        id="footer-image-uploaded"
        :key="`foot-${footerImageNonce}`"
        :disabled="loading"
        accept="image/*"
        class="hidden"
        maxlength="1"
        type="file"
        @change="handleFooterImage"
      >
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Main info</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <f-select
                v-model="build.model"
                :disabled="loading"
                :options="models"
                placeholder="Select model"
                title="Product Model"
              />
              <f-select
                v-model="build.title"
                :disabled="loading"
                :options="titles"
                placeholder="Select build title"
                title="Product Title"
              />
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l label-textarea-wrap">
                  <span>Product details:</span>
                </div>
                <div class="main__content-item_r">
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="build.productDescription"
                    :disabled="loading"
                    :options="quillOptions"
                    @ready="onEditorReady"
                  />
                </div>
              </div>

            </div>

            <div class="main__headline">
              <h5>Blocks</h5>
            </div>

            <div class="main__content-in">
              <draggable v-model="build.blocks" :disabled="loading" :sort="true" handle=".btn-burger" tag="div">
                <div v-for="block in build.blocks" :key="`bock-${block.id}`" class="main__content-in__item">
                  <div class="btn-burger pointer"></div>
                  <div class="wrapper">
                    <div class="media-uploader">
                      <div class="items-holder" @click.prevent="uploadImage(block)">
                        <div v-if="block.image" class="remove-icon">×</div>
                        <img
                          v-if="block.image"
                          :src="blockImage(block)"
                          alt="Upload New Image">
                        <i v-else class="fa fa-upload"></i>
                      </div>
                    </div>
                    <div class="inp-group__textarea">
                      <textarea v-model="block.text" :disabled="loading"></textarea>
                    </div>
                  </div>
                  <button class="field__remove" @click.prevent="removeBlock(block)"></button>
                </div>
              </draggable>
              <div class="flex justify-around">
                <button :disabled="loading" class="btn btn-white btn-bold" @click="addEmptyBlock">
                  <template v-if="build.blocks.length === 0">
                    Add first block
                  </template>

                  <template v-if="build.blocks.length > 0">
                    Add one more block
                  </template>
                </button>
              </div>
            </div>

            <div class="main__headline">
              <h5>Spec blocks</h5>
            </div>

            <div class="main__content-in">
              <draggable v-model="build.specs" :disabled="loading" :sort="true" handle=".btn-burger" tag="div">
                <div v-for="spec in build.specs" :key="`spec-b-${spec.id}`" class="main__content-in__item">
                  <div class="btn-burger pointer"></div>
                  <div class="wrapper flex-wrap">
                    <div class="main__content-in__item-row">
                      <div class="main__content-in__item--l">
                        Title:
                      </div>
                      <div class="main__content-in__item--r">
                        <div class="inp-group">
                          <input v-model="spec.title" type="text" />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-in__item-row">
                      <div class="main__content-in__item--l">
                        Text:
                      </div>
                      <div class="main__content-in__item--r">
                        <div class="inp-group__textarea">
                          <textarea v-model="spec.text" :disabled="loading"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button class="field__remove" @click.prevent="removeSpecBlock(spec)"></button>
                </div>
              </draggable>
              <div class="flex justify-around">
                <button :disabled="loading" class="btn btn-white btn-bold" @click="addEmptySpecBlock">
                  <template v-if="build.specs.length === 0">
                    Add first spec block
                  </template>

                  <template v-if="build.specs.length > 0">
                    Add one more spec block
                  </template>
                </button>
              </div>
            </div>

            <div class="main__headline">
              <h5>Footer Image</h5>
            </div>

            <div class="main__content-in">
              <div v-if="!build.footerImage" class="main__content-in__item">
                <div :disabled="loading" class="media-uploader" @click.prevent="uploadFooterImage">
                  <div class="items-holder">
                    <i class="fa fa-upload"></i>
                  </div>
                </div>
              </div>
              <div v-if="build.footerImage" class="main__content-in__item">
                <div class="fill-image">
                  <div class="remove-icon" @click.prevent="removeFooterImage">×</div>
                  <img :src="footerImageUrl" alt="">
                </div>
              </div>

            </div>

            <div class="main__headline">
              <h5>Manuals</h5>
            </div>

            <div class="main__content-in">
              <div class="main__content-in__item">
                <div class="manuals-wrapper">
                  <div v-for="manual in build.manuals" :key="`manual-${manual.id}`" class="manual-block-wrap">
                    <div class="manual-block">
                      <div class="remove-manual" @click="removeManual(manual)"></div>
                      <i class="fa fa-file-pdf-o"></i>
                    </div>
                    <div class="manual-name">{{ manual.name }}</div>
                  </div>
                  <div :disabled="loading" class="manual-block" @click="addNewManual">
                    <i class="fa fa-upload"></i>
                    <div class="title">Add New Manual</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <feed
            v-if="build && build.id"
            :show-comments="false"
            :show-docs="false"
            :show-history="false"
            :subject-id="build ? build.id : null"
            subject="product-builds"
          />

          <div class="main__content-btns">
            <button :disabled="loading" class="btn btn-big btn-fw" @click="preview">
              <span><i>Preview</i></span>
            </button>
            <button :disabled="loading" class="btn btn-big btn-fw" @click="save">
              <span><i>{{ buttonText }}</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Select from "@/components/inputs/Select.vue";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import Quill from "quill";
import feed from "@/components/partials/orders/OrderFeed.vue";

const blockStruct = {
  id: null,
  text: "",
  image: "",
};

const specBlockStruct = {
  id: null,
  text: "",
  title: "",
};

export default {
  name: "ManageBikeMotorType",
  components: {
    feed,
    draggable,
    CRMLayout,
    "f-select": Select,
  },
  data() {
    return {
      nonce: 1,
      footerImageNonce: 1,
      manualNonce: 1,
      quillOptions: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic"], ["link"],
              ["heading-btn", "green-descriptor-btn", "gray-descriptor-btn", "remove-format-btn"],
            ],
            handlers: {
              "heading-btn": function() {
              },
              "green-descriptor-btn": function() {
              },
              "gray-descriptor-btn": function() {
              },
              "remove-format-btn": function() {
              },
            },
          },
          // 'nbsp-changer': true
        },
        placeholder: "Some text...",
        theme: "snow",
      },
      build: {
        id: null,
        model: null,
        title: null,
        blocks: [],
        specs: [],
        footerImage: null,
        manuals: [],
        productDescription: "",
        activities: [],
        active: false,
      },
      loading: true,
      selectedBlock: null,
    };
  },
  computed: {
    ...mapGetters({
      models: "models/list",
      titles: "build/buildTitles",
    }),
    buttonText() {
      if (this.loading) {
        return "Working. Please be patient...";
      }

      if (this.createMode) {
        return "Save Product Build";
      } else {
        return "Update Product Build";
      }
    },
    footerImageUrl() {
      if (this.build.footerImage instanceof File) {
        return URL.createObjectURL(this.build.footerImage);
      }

      return this.build.footerImage || "";
    },
    createMode() {
      return !this.$route?.params?.id;
    },
  },
  mounted() {
    this.loading = true;

    this.fetchData();
  },
  methods: {
    addCustomButtons() {
      const quill = this.$refs.myQuillEditor.quill;
      const toolbar = quill.getModule("toolbar");

      // Функция для создания кнопки с привязанным обработчиком нажатия
      const createButton = (container, text, className, formatFunction, styles) => {
        const button = document.createElement("button");
        button.classList.add("custom-editor-btn", className);
        button.innerHTML = text;
        button.style.borderRadius = "10px";
        button.style.padding = "5px 10px";
        button.style.margin = "0 5px";
        button.style.cursor = "pointer";
        button.style.fontSize = "12px";
        button.style.width = "auto";
        button.style.border = "1px solid gray";

        if (styles) {
          for (let style of Object.keys(styles)) {
            button.style[style] = styles[style];
          }
        }

        container.appendChild(button);

        // Добавляем обработчик события клика
        button.addEventListener("click", formatFunction);

        return button;
      };

      // Находим контейнер для наших кнопок
      const containers = toolbar.container.querySelectorAll(".ql-heading-btn, .ql-green-descriptor-btn, .ql-gray-descriptor-btn, .ql-remove-format-btn");

      containers.forEach(container => {
        const parent = container.parentNode;
        parent.removeChild(container);

        if (container.className === "ql-heading-btn") {
          createButton(parent, "heading", "heading-btn", () => {
            toolbar.handlers["heading-btn"].call(toolbar);
          }, {
            fontWeight: "bold",
          });
        } else if (container.className === "ql-green-descriptor-btn") {
          createButton(parent, "green descriptor", "green-descriptor-btn", () => {
            toolbar.handlers["green-descriptor-btn"].call(toolbar);
          }, {
            color: "rgb(194, 233, 0)",
          });
        } else if (container.className === "ql-gray-descriptor-btn") {
          createButton(parent, "gray descriptor", "gray-descriptor-btn", () => {
            toolbar.handlers["gray-descriptor-btn"].call(toolbar);
          }, {
            background: "rgb(194, 233, 0)",
            color: "#000",
          });
        } else if (container.className === "ql-remove-format-btn") {
          createButton(parent, "clear format", "remove-format-btn", () => {
            toolbar.handlers["remove-format-btn"].call(toolbar);
          }, {
            background: "red",
            color: "#fff",
            border: "red",
            lineHeight: "10px",
          });
        }
      });
    },
    onEditorReady() {
      const quill = this.$refs.myQuillEditor.quill;

      // Регистрируем пользовательские форматы
      const Inline = Quill.import("blots/inline");

      // Создаем класс для заголовка
      class HeadingFormat extends Inline {
        static create() {
          const node = super.create();
          // node.style.color = "#474747";
          // node.style.fontWeight = "bold";
          // node.style.fontSize = "18px";
          node.setAttribute("class", "f-heading");
          // node.classList.add("f-heading");
          return node;
        }
      }

      HeadingFormat.blotName = "heading-format";
      HeadingFormat.tagName = "h3";
      Quill.register(HeadingFormat);

      // Создаем класс для зеленого дескриптора
      class GreenDescriptor extends Inline {
        static create() {
          const node = super.create();
          node.style.color = "rgb(194, 233, 0)";
          // node.style.fontWeight = "399";
          // node.classList.add("f-green-descriptor");
          node.setAttribute("class", "f-green-descriptor");
          return node;
        }
      }

      GreenDescriptor.blotName = "green-descriptor-format";
      GreenDescriptor.tagName = "span";
      Quill.register(GreenDescriptor);

      // Создаем класс для серого дескриптора
      class GrayDescriptor extends Inline {
        static create() {
          const node = super.create();
          node.style.color = "#000000";
          node.style.backgroundColor = "rgb(194, 233, 0)";
          node.setAttribute("class", "f-gray-descriptor");
          return node;
        }
      }

      GrayDescriptor.blotName = "gray-descriptor-format";
      GrayDescriptor.tagName = "span";
      Quill.register(GrayDescriptor);

      const q = this.$refs.myQuillEditor.quill;
      const toolbar = q.getModule("toolbar");

      toolbar.handlers["heading-btn"] = function() {
        const range = quill.getSelection();

        if (range) {
          const format = quill.getFormat(range);

          if (!format["heading-format"]) {
            quill.formatText(range.index, range.length, "heading-format", true);
          }
        }
      };

      toolbar.handlers["green-descriptor-btn"] = function() {
        const range = quill.getSelection();

        if (range) {
          const format = quill.getFormat(range);

          if (format["color"] !== "#c2e900") {
            quill.formatText(range.index, range.length, "green-descriptor-format", true);
          }
        }
      };

      toolbar.handlers["gray-descriptor-btn"] = function() {
        const range = quill.getSelection();
        if (range) {
          const format = quill.getFormat(range);

          if (format["background"] !== "#c2e900") {
            quill.formatText(range.index, range.length, "gray-descriptor-format", true);
          }
        }
      };

      toolbar.handlers["remove-format-btn"] = function() {
        const range = quill.getSelection();
        if (range) {
          quill.removeFormat(range.index, range.length);
        }
      };

      // Создаем и добавляем кнопки в интерфейс
      this.addCustomButtons();
    },
    removeManual(manual) {
      this.$set(this.build, "manuals", this.build.manuals.filter(item => {
        return item.id !== manual.id;
      }));
    },
    addNewManual() {
      document.getElementById("blocks-manuals-uploaded").click();
    },
    removeFooterImage() {
      this.build.footerImage = null;
    },
    uploadFooterImage() {
      if (!this.build.footerImage) {
        document.getElementById("footer-image-uploaded").click();
      }
    },
    handleFooterImage(event) {
      this.build.footerImage = event.target.files[0];

      this.footerImageNonce++;
    },
    handleManual(event) {
      let maxID = null;
      for (let manual of this.build.manuals) {
        if (manual.id > maxID) {
          maxID = manual.id;
        }
      }

      if (maxID === null) {
        maxID = 0;
      }

      maxID += 1;

      this.build.manuals.push({
        id: maxID,
        file: event.target.files[0],
        name: event.target.files[0].name,
      });

      this.manualNonce++;
    },
    handleImage(event) {
      let file = event.target.files[0];

      this.build.blocks = this.build.blocks.map((block) => {
        if (block.id === this.selectedBlock) {
          block.image = file;
        }

        return block;
      });

      this.nonce++;
    },
    uploadImage(block) {
      if (!block.image) {
        this.selectedBlock = block.id;

        document.getElementById("blocks-image-uploaded").click();
      }
    },
    removeBlock(block) {
      this.$set(this.build, "blocks", this.build.blocks.filter((b) => b.id !== block.id));
    },
    removeSpecBlock(spec) {
      this.$set(this.build, "specs", this.build.specs.filter((b) => b.id !== spec.id));
    },
    blockImage(block) {
      if (block.image instanceof File) {
        return URL.createObjectURL(block.image);
      }

      return block.image || "";
    },
    addEmptyBlock() {
      let maxID = null;
      for (let block of this.build.blocks) {
        if (maxID === null) {
          maxID = block.id;
        } else {
          if (maxID < block.id) {
            maxID = block.id;
          }
        }
      }

      if (maxID === null) {
        maxID = 1;
      } else {
        maxID = maxID + 1;
      }

      let newBlock = { ...blockStruct };
      newBlock.id = maxID;

      this.build.blocks.push(newBlock);
    },
    addEmptySpecBlock() {
      let maxID = null;
      for (let spec of this.build.specs) {
        if (maxID === null) {
          maxID = spec.id;
        } else {
          if (maxID < spec.id) {
            maxID = spec.id;
          }
        }
      }

      if (maxID === null) {
        maxID = 1;
      } else {
        maxID = maxID + 1;
      }

      let newBlock = { ...specBlockStruct };
      newBlock.id = maxID;

      this.build.specs.push(newBlock);
    },
    fetchData() {
      this.$store.dispatch("build/helper").then(async () => {
        if (!this.createMode) {
          this.loadProductBuild();
        } else {
          this.loading = false;
        }
      }).catch(error => {
        console.log("Error while fetching data [Helper]", error);
      });
    },
    loadProductBuild() {
      if (!this.createMode) {
        this.$store
          .dispatch("bikes/loadProductBuildSingle", this.$route.params.id)
          .then(result => {
            this.$set(this.build, "model", result.model);
            this.$set(this.build, "title", result.title);
            this.$set(this.build, "blocks", result.data.blocks);
            this.$set(this.build, "manuals", result.data.manuals);
            this.$set(this.build, "specs", result.data.specs);
            this.$set(this.build, "footerImage", result.data.footer_image);
            this.$set(this.build, "id", result.id);
            this.$set(this.build, "productDescription", result.data.product_description);
            this.$set(this.build, "activities", result.data.activities);
            this.$set(this.build, "active", result.data.active);

            this.$store.dispatch("orderFeed/load", {
              product_build_id: this.$route.params.id,
              type: "product-builds",
            }).catch(err => {
              console.log(err);
            }).finally(() => {
              setTimeout(() => {
                this.loading = false;
              }, 1900);
            });
          })
          .catch(() => {
            this.$root.$emit("modalShow", {
              text: "Something went wrong. Try later",
              type: "error",
            });
          });
      }
    },
    preview() {
      let data = this.save(true);

      this.$store
        .dispatch("bikes/manageProductBuildPreview", {
          formData: data,
          id: this.build.id,
        })
        .then(result => {
          const a = document.createElement("a");
          a.href = result.preview_url;
          a.target = "_blank";

          a.click();

          a.remove();
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1900);
        });
    },
    save(preview = false) {
      if (!this.build.model) {
        this.$root.$emit("modalShow", { text: "Please fill \"Product model\" field", type: "error" });
        return;
      }

      if (!this.build.title) {
        this.$root.$emit("modalShow", { text: "Please fill \"Product title\" field", type: "error" });
        return;
      }

      if (this.build.blocks.length === 0 && this.build.specs.length === 0) {
        this.$root.$emit("modalShow", {
          text: "Please fill in at least one block or spec block, otherwise, this configuration is meaningless.",
          type: "error",
        });
        return;
      }

      if (this.build.blocks.length > 0) {
        let emptyBlockExists = false;

        for (let block of this.build.blocks) {
          if (!block.image || !block.text) {
            emptyBlockExists = true;
            break;
          }
        }

        if (emptyBlockExists) {
          this.$root.$emit("modalShow", {
            text: "Fill in all fields in each block or delete the blocks with missing fields.",
            type: "error",
          });
          return;
        }
      }

      if (this.build.specs.length > 0) {
        let emptyBlockExists = false;

        for (let spec of this.build.specs) {
          if (!spec.title || !spec.text) {
            emptyBlockExists = true;
            break;
          }
        }

        if (emptyBlockExists) {
          this.$root.$emit("modalShow", {
            text: "Fill in all fields in each spec block or delete the blocks with missing fields.",
            type: "error",
          });
          return;
        }
      }

      let blocks = {};

      for (let block of this.build.blocks) {
        blocks[block.id] = {
          text: block.text,
          image: block.image instanceof File ? `block-image-${block.id}` : block.image,
        };
      }

      let manuals = {};

      for (let manual of this.build.manuals) {
        manuals[manual.id] = {
          name: manual.name,
          file: manual.file instanceof File ? `block-file-${manual.id}` : manual.file,
        };
      }

      let data = {
        model_id: this.build.model.id,
        title_id: this.build.title.id,
        product_description: this.build.productDescription || "",
        blocks: blocks,
        manuals: manuals,
        specs: this.build.specs,
        active: (this.createMode || preview) ? false : this.builde.active,
      };

      this.loading = true;

      let formData = new FormData();

      for (let block of this.build.blocks) {
        if (block.image instanceof File) {
          formData.append(`block-image-${block.id}`, block.image);
        }
      }

      for (let manual of this.build.manuals) {
        if (manual.file instanceof File) {
          formData.append(`block-file-${manual.id}`, manual.file);
        }
      }

      if (this.build.footerImage instanceof File) {
        formData.append("footer_image", this.build.footerImage);
      }

      formData.append("model_id", data.model_id);
      formData.append("title_id", data.title_id);
      formData.append("product_description", data.product_description);
      formData.append("blocks", JSON.stringify(data.blocks));
      formData.append("manuals", JSON.stringify(data.manuals));
      formData.append("specs", JSON.stringify(data.specs));
      formData.append("preview", preview);

      if (preview) {
        return formData
      }

      this.$store
        .dispatch("bikes/manageProductBuild", {
          formData: formData,
          id: this.build.id,
        })
        .then(result => {
          if (this.createMode) {
            this.$router.push("/product-builds/" + result.id);
          } else {
            this.loadProductBuild();
          }

          if (preview) {
            const a = document.createElement("a");
            a.href = result.data.data.preview_url;
            a.target = "_blank";

            a.click();

            a.remove();
          }

          this.$root.$emit("modalShow", {
            text: `Product Build ${this.createMode ? "created" : "updated"}`,
            type: "info",
          });
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1900);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main__content-in {
  &__item {
    display: flex;
    margin-bottom: 15px;
    justify-content: center;

    .btn-burger {
      align-self: center;
      margin-right: 0;
      padding: 20px;
      background-size: 18px;
      cursor: grab !important;
      //noinspection CssInvalidPropertyValue
      cursor: -webkit-grab !important;
    }

    .wrapper {
      display: flex;
      gap: 20px;
      background: #ffffff;
      border: 1px solid #979797;
      box-sizing: border-box;
      border-radius: 5px;
      max-width: 976px;
      width: calc(100% - 48px);
      padding: 12px;
      margin: 0 0;

      &.flex-wrap {
        flex-wrap: wrap;
      }

      .inp-group__textarea {
        flex: 1;
        @media screen and (max-width: 575px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 575px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .field__remove {
      position: relative;
      right: 0;
      transform: none;
      margin-right: 0;
      width: 35px;
      align-self: center;
    }

    &--l {
      width: 44%;
      text-align: right;
      align-self: flex-start;
      padding: 6px 0;
      color: #000;
      font-weight: 400;
      font-size: 14px;
      display: inline-block;
      @media screen and (max-width: 991px) {
        text-align: left;
        padding: 0;
      }
    }

    &--r {
      width: 56%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      @media screen and (max-width: 991px) {
        width: 100%;
        max-width: 330px;
      }
    }

    &-row {
      gap: 20px;
      display: flex;
      width: 100%;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        gap: 4px;
      }
    }

  }

  .media-uploader {
    font-family: "Object Sans", sans-serif;
    letter-spacing: 0.2px;
    color: #777;
    transition: 0.2s linear;
    padding: 0;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0;
    //min-height: 216px;
    text-align: left;
    z-index: 4;

    .items-holder {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 150px;
      height: 150px;
      background: white;
      position: relative;
      border-radius: 4px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
      overflow: hidden;

      .remove-icon {
        position: absolute;
        top: -1px;
        right: 6px;
        font-size: 38px;
        color: black;
        z-index: 7;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        margin: auto;
        z-index: 1;
      }

      .fa {
        margin: auto;
      }
    }

    &:hover {
      background: rgba(229, 229, 229, 0.73);
      cursor: pointer;
    }
  }

  .fill-image {
    width: 100%;
    max-width: 976px;
    margin: 0 auto;
    position: relative;

    img {
      width: 100%;
      border-radius: 4px;
    }

    .remove-icon {
      position: absolute;
      top: -1px;
      right: 6px;
      font-size: 38px;
      color: black;
      z-index: 7;
      cursor: pointer;
    }
  }

  .label-textarea-wrap {
    align-self: flex-start;
    padding-top: 14px;
    @media (max-width: 991px) {
      width: 100%;
      padding-top: 0;
    }
  }
}

.manual-block {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  text-align: center;
  box-sizing: border-box;
  gap: 16px;
  cursor: pointer;
  background: white;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  .title {
    font-size: 14px;
  }

  .fa-file-pdf-o {
    font-size: 40px;
  }
}

.remove-manual {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 15px;
  height: 15px;
  z-index: 99;
  transition: transform 0.2s ease;
  font-size: 0;
  cursor: pointer;

  &:before {
    display: block;
    width: 15px;
    height: 2px;
    margin: auto;
    background-color: #000;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: " ";
    bottom: 0;
    border-radius: 2px;
    transform: rotate(45deg);
  }

  &:after {
    border-radius: 2px;
    transform: rotate(-45deg);
    display: block;
    width: 15px;
    height: 2px;
    margin: auto;
    background-color: #000;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: " ";
    bottom: 0;
  }
}

.manual-name {
  font-size: 10px;
  line-height: 12px;
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  height: 12px;
  overflow: hidden;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #454545;
  padding-top: 6px;
  max-width: 150px;
  display: block;
  white-space: nowrap;
}

.manuals-wrapper {
  width: 100%;
  min-height: 330px;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #979797;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;
}

.custom-editor-btn {
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  transition: all 0.3s;
}

.custom-editor-btn:hover {
  background-color: #e4e4e4;
}

.heading-btn {
  color: #474747;
  font-weight: bold;
}

.green-descriptor-btn {
  color: rgb(194, 233, 0);
}

.gray-descriptor-btn {
  color: #000000;
  background-color: #474747;
}

.remove-format-btn {
  color: #ff0000;
}
</style>
