<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Import Bikes List</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Import Bikes List</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Download template</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <!--suppress HtmlFormInputWithoutLabel -->
                    <a href="" @click.prevent="downloadTemplate">Download</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Upload file with bikes:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <!--suppress HtmlFormInputWithoutLabel -->
                    <input
                      :key="`if-${nonce}`"
                      accept=".xlsm"
                      maxlength="1"
                      type="file"
                      @change="handleFile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content-btns">
            <button :disabled="loading" class="btn btn-big btn-fw" @click="importBikes">
              <span><i>Import</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";

export default {
  name: "BikesImport",
  components: {
    CRMLayout,
  },
  data() {
    return {
      nonce: 1,
      bikes: null,
      loading: false,
    };
  },
  methods: {
    importBikes() {
      this.loading = true;

      this.$store.dispatch("bikes/importBikes", this.bikes).then((count) => {
        this.$root.$emit("modalShow", {
          text: `${count} bike(s) imported successfully`,
          type: "info",
        });

        this.$router.push({ path: `/bikes` });
      })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadTemplate() {
      this.$store.dispatch("bikes/downloadImportTemplate").then((url) => {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = "Import Bikes Template.xlsm";

        link.click();

        setTimeout(() => {
          link.remove();
        }, 300);
      });
    },
    handleFile(event) {
      if (event.target.files.length > 0) {
        this.bikes = event.target.files[0];
      }
    }
  },
};
</script>
