import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "../../helpers/parseError";
import OrderInvoice from "../../models/OrderInvoice";
import hash from "object-hash";

export const DISCOUNT_VALUE = "value";
export const DISCOUNT_PERCENT = "percent";

const parsePriceFloat = price => parseFloat(Number(price).toFixed(2));

// initial state
const state = {
  discount_type: DISCOUNT_PERCENT,
  discount_value: 0,
  last_hash: "",
  invoices: [],
  subTotal: 0,
  discount: 0,
  taxBase: 0,
  taxes: 0,
  total: 0,
  taxTitle: "",
  taxPercent: 0,
  remaining: 0,
  paid: 0
};

// getters
const getters = {
  discountType: state => state.discount_type,
  discountValue: state => state.discount_value,
  subTotal: state => state.subTotal,
  discount: state => state.discount,
  taxBase: state => state.taxBase,
  taxes: state => state.taxes,
  total: state => state.total,
  taxTitle: state => state.taxTitle,
  taxPercent: state => state.taxPercent,
  remaining: state => state.remaining,
  paid: state => state.paid,
  forSelectDiscount() {
    return [
      {
        label: "Discount, %",
        value: DISCOUNT_PERCENT
      },
      {
        label: "Discount, €",
        value: DISCOUNT_VALUE
      }
    ];
  },
  currentLabelType: (state, getters) => {
    for (let type of getters["forSelectDiscount"]) {
      if (type.value === state.discount_type) {
        return type.label;
      }
    }
    return "";
  },
  isPercent: state => state.discount_type === DISCOUNT_PERCENT,
  getTotalPrice: (state, getters) => {
    return getters["getSubtotalPrice"] - getters["getDiscountPrice"];
  },
  getSubtotalPrice: (state, getters, rootState, rootGetters) => {
    let total_price = 0;
    const country = rootGetters["countries/getCountry"](
      rootState.orderAddress.delivery_address.country_id
    );
    rootState.orderItem.data.products.map(product => {
      total_price += product.end_price(country || null) * product.quantity;
      return product;
    });

    return total_price;
  },
  getDiscountPrice: (state, getters) => {
    if (state.discount_value === 0) {
      return 0;
    }

    if (state.discount_type === DISCOUNT_VALUE) {
      return state.discount_value;
    }

    if (state.discount_type === DISCOUNT_PERCENT) {
      let subtotal = getters["getSubtotalPrice"] || 0;
      if (!subtotal) {
        return 0;
      }

      return parsePriceFloat(subtotal * (state.discount_value / 100));
    }
    return 0;
  },
  getTax: (state, rootState, getters, rootGetters) => {
    let deliveryCountry = rootGetters["orderAddress/getDeliveryCountry"];
    if (!deliveryCountry || !deliveryCountry.tax) {
      return null;
    }

    return deliveryCountry.tax;
  },
  getTaxPercent: (state, getters) => {
    let tax = getters["getTax"];
    return tax ? tax.percent : 0;
  },
  getTaxBasePrice: (state, getters, rootState) => {
    let total_price = 0;
    if (getters["getDiscountPrice"] !== 0) {
      return (
        (getters["getSubtotalPrice"] - getters["getDiscountPrice"]) /
        (1 + getters["getTaxPercent"] / 100)
      );
    }
    rootState.orderItem.data.products.map(product => {
      let key = "price_tax_base";
      if (product.pre_sale) {
        key = "ex_" + key;
      }
      if (product.custom_price) {
        total_price +=
          (product.custom_price / (1 + getters["getTaxPercent"] / 100)) *
          product.quantity;
      } else {
        total_price += product.build.prices[key] * product.quantity;
      }
      for (let extra of product.extra) {
        total_price += extra.prices.price_tax_base * extra.pivot.qty;
      }
      return product;
    });

    return total_price;
  },
  getTotalPaid: (state, getters) => {
    let sum = 0;
    getters["getPaidInvoices"].map(invoice => (sum += invoice.amount));

    return sum;
  },
  getPaymentMethods: state => {
    return state.invoices
      .filter(invoice => invoice.hasPaymentMethod())
      .map(invoice => {
        return {
          title: `Paid (${invoice.payment_method.title})`,
          price: invoice.amount / 100
        };
      });
  },
  getPaidInvoices: state => {
    return state.invoices.filter(invoice => invoice.isPaymentWasConfirmed());
  },
  wasSuccessfulPayment: (state, getters) => {
    return !!getters["getPaidInvoices"].length;
  },
  wasInvoice: state => {
    return !!state.invoices.length;
  },
  orderIsFullPaid: state => {
    return state.remaining === 0;
  },
  getHash: state => {
    return hash([state.discount_value, state.discount_type]);
  },
  wasEdited: (state, getters, rootState, rootGetters) => {
    if (rootState.orderItem.page_is_created) {
      return hash([0, DISCOUNT_PERCENT]) !== rootGetters["orderPrice/getHash"];
    } else {
      return rootGetters["orderPrice/getHash"] !== state.last_hash;
    }
  }
};

// actions
const actions = {
  calculate(context) {
    axios
      .post(API_ENDPOINT + "v2/builds/prices", {
        items: context.rootGetters["orderItem/products"].filter(item => !!item.build).map(item => {
          return {
            id: item.id,
            build_id: item.build?.id,
            qty: item.quantity,
            custom_price: item.custom_price
          };
        }),
        delivery_country:
          context.rootGetters["orderAddress/getDeliveryCountryId"],
        discount: {
          type: context.getters["discountType"],
          value: context.getters["discountValue"]
        },
        order_id: context.rootGetters["orderItem/orderId"] || null
      })
      .then(result => {
        context.commit("setPrice", result.data.data);
        if (result.data.data.items) {
          context.commit(
            "orderItem/updateProductPrices",
            result.data.data.items,
            { root: true }
          );
        }
      });
  },
  parseFromOrderResponse({ dispatch, getters }, response) {
    if (response.data.discount_type) {
      dispatch("changeType", response.data.discount_type);
    }
    if (response.data.discount_value) {
      dispatch("setValue", response.data.discount_value);
    }

    state.last_hash = getters["getHash"];
  },
  changeType({ commit, state, dispatch }, value) {
    commit("set_type", value);
    dispatch("setValue", state.discount_value);
  },
  setValue({ state, commit }, value) {
    value = parseFloat(value);
    if (value < 0) {
      value = 0;
    }

    if (state.discount_type === DISCOUNT_PERCENT && value > 50) {
      value = 50;
    }

    commit("set_value", value);
  },
  setDefault({ dispatch }) {
    dispatch("changeType", DISCOUNT_PERCENT);
    dispatch("setValue", 0);
  },
  loadInvoices({ commit, rootState, dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_ENDPOINT + `orders/${rootState.orderItem.id}/payments`)
        .then(response => {
          commit("clear_invoices");
          response.data.map(e =>
            commit("add_invoice", OrderInvoice.parseFromResponse(e))
          );
          dispatch("calculate");
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  createInvoice({ dispatch, rootState }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(API_ENDPOINT + `payments/${rootState.orderItem.id}`, data)
        .then(response => {
          dispatch("loadInvoices");
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  deleteInvoice({ dispatch, rootState }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(API_ENDPOINT + `payments/${rootState.orderItem.id}/${id}`)
        .then(response => {
          dispatch("loadInvoices");
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  uploadFileInvoice({ dispatch, rootState }, formData) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}payments/${
        rootState.orderItem.id
      }/${formData.get("invoice_id")}/receipt`;
      axios
        .post(url, formData)
        .then(response => {
          let statusId = response.data.order_status;
          dispatch("orderStatus/changeLocalStatus", statusId, { root: true });
          dispatch("orderStatus/loadOrderStatusHistory", {}, { root: true });
          dispatch("loadInvoices").finally(() => resolve(response));
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  clearInvoices({ commit }) {
    commit("clear_invoices");
  }
};

// mutations
const mutations = {
  setPrice(state, prices) {
    state.subTotal = prices.subtotal;
    state.discount = prices.discount;
    state.taxBase = prices.tax_base;
    state.taxes = prices.taxes;
    state.total = prices.total;
    state.taxTitle = prices.tax_title;
    state.taxPercent = prices.tax_percent;
    state.remaining = prices.remaining;
    state.paid = prices.paid;
  },
  set_type(state, type) {
    state.discount_type = type;
  },
  set_value(state, value) {
    state.discount_value = value;
  },
  clear_invoices(state) {
    state.invoices = [];
  },
  add_invoice(state, invoice) {
    state.invoices.push(invoice);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
