import { STORAGE_ENDPOINT } from "@/utils/api";

function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export class OrderProduct {
  id = null;
  product_id;
  order_id = null;
  frame_number = null;
  quantity = 0;
  name = null;
  is_pre_order = false;
  price = 0;
  singlePrice = 0;
  pre_order_price = 0;
  extra = [];
  pre_sale_price = false;
  deliveryAddress = null;

  constructor(product_id, order = null, deliveryAddress = null) {
    this.product_id = parseInt(product_id);
    this.order_id = order ? parseInt(order.id) : null;
    this.order = order;
    this.deliveryAddress = deliveryAddress;
  }

  _description = "";

  get description() {
    return this._description;
  }

  set description(value) {
    this._description = stripHtml(value);
  }

  _img = null;

  get img() {
    return this._img;
  }

  set img(value) {
    this._img = STORAGE_ENDPOINT + value;
  }

  get deliveryCountry() {
    return this.order
      ? this.order.delivery_address.country
      : this.deliveryAddress;
  }

  get end_price() {
    let ctx = this;
    return function(country = null) {
      if (!country) {
        country = ctx.deliveryCountry;
      }
      let tax = country["tax"];
      let key;
      let price;
      if (this.custom_price) {
        return this.custom_price;
      }
      switch (tax.title) {
        case "AD":
          key = "price_for_andorra";
          break;
        case "EU":
          key = "price_for_eu";
          break;
        default:
          key = "price_for_other";
          break;
      }
      let extraKey = key;
      if (this.pre_sale) {
        key = "ex_" + key;
      }
      if (key.includes("price_for_eu")) {
        price = this.build.prices[key].find(item => {
          return item.name === country.country_name;
        }).price;
      } else {
        price = this.build.prices[key];
      }

      for (let extra of ctx.extra) {
        if (extraKey.includes("price_for_eu")) {
          price +=
            extra.prices[extraKey].find(item => {
              return item.name === country.country_name;
            }).price * extra.pivot.qty;
        } else {
          price += extra.prices[extraKey] * extra.pivot.qty;
        }
      }
      return price;
    };
  }

  get total_price() {
    return this.quantity * this.end_price();
  }

  static getPreview(build) {
    if (build?.media_content) {
      return build?.media_content?.preview;
    } else if (build?.mediaItems) {
      return build["mediaContent"]["preview"];
    } else {
      return null;
    }
  }

  static parseFromResponse(data, order_id) {
    let order_product = new OrderProduct(data.id, order_id);
    order_product.id = data.id || null;
    order_product.frame_number = data.frame_number || null;
    order_product.custom_price = data["custom_price"] || 0;
    order_product.is_testing = data["is_testing"] || false;
    order_product.quantity = data["qty"] || 0;
    order_product.name = data.build?.web_bike_title;
    order_product._img = OrderProduct.getPreview(data.build);
    order_product.is_pre_order = data["purchase_type"] === "reservation";
    order_product.build = data.build;
    order_product.extra = data["extra_items"];
    order_product.pre_sale = data.pre_sale_price;
    order_product.moto_style = data.moto_style;
    order_product._description = data.build?.description;

    return order_product;
  }
}
