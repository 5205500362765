<template>
  <div class="add-product">
    <div class="add-product-title" @click="toggleShowInfo">
      <span :class="{ reverse: !showInfo }" class="arr"></span>
      <span>
        {{ getBuildTitle(build) }} from
        {{ $crConverter(getPrice(build) / 100) }}
      </span>
    </div>
    <div v-if="showInfo" class="add-product-info">
      <div class="add-product-action-wrap">
        <a class="add-product-action" @click="addProduct(build)">add</a>
      </div>
      <div v-if="false" class="characteristic-wrap">
        <div
          v-for="part in product.parts"
          :key="part.id"
          class="characteristic"
        >
          <div class="characteristic-name">{{ part.category.title }}</div>
          <div class="characteristic-value">{{ part.title }}</div>
        </div>
      </div>
      <div class="add-product-info-footer">
        <div class="add-product-price">
          {{ $crConverter(getPrice(build) / 100) }}
        </div>
        <a class="add-product-btn" @click="addProduct(build)">Add to order</a>
      </div>
    </div>
  </div>
</template>

<script>
import { OrderProduct } from "@/store/models/OrderProduct";
import { mapGetters } from "vuex";

export default {
  name: "AddingProduct",
  props: {
    build: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showInfo: false
    };
  },
  computed: {
    ...mapGetters({
      deliveryAddress: "orderAddress/getDeliveryCountry"
    })
  },
  methods: {
    getBuildTitle(build) {
      return build.ms_title;
    },
    toggleShowInfo() {
      this.showInfo = !this.showInfo;
    },
    addProduct(build) {
      if (this.deliveryAddress === null) {
        this.$root.$emit("modalShow", {
          type: "error",
          text: "Select delivery address pls"
        });
      } else {
        let orderProduct = new OrderProduct(
          build.id,
          null,
          this.deliveryAddress
        );
        orderProduct.quantity = 1;
        orderProduct.custom_price = null;
        orderProduct.is_testing = false;
        orderProduct.build = build;
        orderProduct._img = build.img;
        orderProduct.name = build.web_bike_title;
        orderProduct._description = build.desc || "";
        orderProduct.pre_sale = false;
        orderProduct.extra = [];

        let key = "";
        let characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let charactersLength = characters.length;
        for (let i = 0; i < 10; i++) {
          key += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }

        orderProduct.key = key;

        this.$store.dispatch("orderItem/addProduct", orderProduct).then(() => {
          this.$store.dispatch("orderPrice/calculate");
          this.toggleShowInfo();
          this.$emit("selectProduct");
        });
      }
    },
    getPrice(build) {
      return build.prices?.pivot?.price;
    }
  }
};
</script>

<style scoped>
.add-product {
  padding: 0 10px 20px 0;
  cursor: auto;
}

.add-product-info {
  padding: 10px 10px 0;
}

.characteristic-wrap {
  padding-left: 8px;
  padding-top: 0;
  display: inline-grid;
  padding-bottom: 0;
  max-width: 516px;
}

.characteristic {
  padding: 10px;
  display: flex;
  align-items: flex-start;
}

.characteristic-name,
.characteristic-value {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  display: inline-block;
  align-items: center;
}

.characteristic-value {
  padding-left: 10px;
}

.characteristic-name {
  width: 110px;
  min-width: 110px;
}

.add-product-action-wrap {
  padding: 10px;
}

.add-product-action {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  color: #000000;
}

.add-product-info-footer {
  padding: 10px;
}

.add-product-price {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  display: inline-block;
  margin-right: 40px;
}

.add-product-btn {
  display: inline-block;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: bold;
  padding: 13px 10px 10px;
  width: 400px;
  text-align: center;
}

.add-product-title {
  cursor: pointer;
}
</style>
